// From BP-6166, no idea what this is supposed to do... - DAJ 20200618
// ¯\_(ツ)_/¯
piAId = '698113';
piCId = '47678';
piHostname = 'pi.pardot.com';
(function() {
function async_load(){
var s = document.createElement('script'); s.type = 'text/javascript';
s.src = ('https:' == document.location.protocol ? 'https://pi' : 'http://cdn') + '.pardot.com/pd.js';
var c = document.getElementsByTagName('script')[0]; c.parentNode.insertBefore(s, c);
}
if(window.attachEvent) { window.attachEvent('onload', async_load); }
else { window.addEventListener('load', async_load, false); }
})();